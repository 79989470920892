import './main.sass'

import { library, dom } from '../../node_modules/@fortawesome/fontawesome-svg-core'
import {
  faSort, faSortAlphaDownAlt, faSortAlphaUpAlt,
  faAngleUp, faAngleDown, faAngleRight,
  faAngleLeft, faAngleDoubleLeft,
  faAngleDoubleRight, faUser,
  faEdit, faTrash,
  faCircle, faDotCircle,
  faBars, faBell, faFolder, faFileCsv, faWaveSquare,
  faFile, faDownload, faVolumeMute, faUpload
} from '../../node_modules/@fortawesome/free-solid-svg-icons'

library.add(
  faSort, faSortAlphaUpAlt, faSortAlphaDownAlt,
  faAngleUp, faAngleDown, faAngleLeft,
  faAngleRight, faAngleLeft, faAngleDoubleLeft,
  faAngleDoubleRight, faUser,
  faEdit, faTrash, faCircle, faDotCircle,
  faBars, faBell, faFolder, faFileCsv, faWaveSquare,
  faFile, faDownload, faVolumeMute, faUpload
)

dom.watch()

document.addEventListener('DOMContentLoaded', () => {
  var dropdown = document.querySelector('.dropdown')
  if (dropdown) {
    dropdown.addEventListener('click', function (event) {
      event.stopPropagation()

      dropdown.classList.toggle('is-active')
    })

    var body = document.querySelector('body')
    body.addEventListener('click', function (event) {
      dropdown.classList.remove('is-active')
    })
  }

  // Add a keyboard event to close all modals
  document.addEventListener('keydown', (event) => {
    const e = event || window.event

    if (e.keyCode === 27) { // Escape key
      // FIXME closeAllModals()
    }
  })
})
